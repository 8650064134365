<template>
    <div class="-mt-3">

        <div v-if="!isLoading">
            <div class="header w-full h-64 bg-blue-600 rounded-3xl rounded-t-none px-3 ">
            </div>
            <div class="bg-white rounded-full w-40 h-40 shadow-lg flex flex-col justify-center mx-auto -mt-56">
                <h4 class="text-gray-600 text-sm text-center font-semibold mt-2">Nilai Tertinggi</h4>
                <h2 class="text-center text-5xl font-semibold mt-2 uppercase text-yellow-500">{{ nilai_tertinggi.nilai_akhir }}</h2>
            </div>

            <div class="hero px-4 mt-3">
                <div class="bg-white w-full rounded-2xl p-3 shadow-lg flex flex-col justify-center py-6">
                    <h2 class="text-center text-lg font-semibold text-gray-800 uppercase mb-4">{{ kuis.judul_kuis }}</h2>

                    <div class="flex justify-center ">
                        <div class="mx-2 w-40 p-4 text-center rounded-lg border-yellow-500 border-2" :key="index" v-for="(data, index) in nilai_tertinggi.nilai_detail">
                            <h2 class="font-bold text-2xl">{{ data.nilai }}</h2>
                            <h2 class="text-gray-800 font-semibold">{{ data.singkatan }}</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div class="px-4 mt-6 mb-6">
                <div class="bg-white w-full rounded-2xl p-3 shadow-lg">
                    <div id="chart" >
                        <vue-apex-charts
                            type="line" height="350" :options="chartOptions" :series="series"></vue-apex-charts>
                    </div>
                </div>
            </div>

        </div>

        <loading-modal :isShow="isLoading"/>
    </div>
</template>

<script>
    import LoadingModal from '../../components/LoadingModal.vue';
    import { errorHandler, showErrorNotif } from '../../helpers/Tools';
    import VueApexCharts from 'vue-apexcharts'
    export default {
        name: 'RaporDetail',
        components : {
            LoadingModal, VueApexCharts
        },
        data (){
            return {
                isLoading      : true,
                kuis           : null,
                last_nilai     : [],
                nilai_tertinggi: null,

                series: [{
                    name: 'Nilai Akhir',
                    data: []
                }],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], 
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: [],
                    },
                    points: []
                },

            }
        },
        mounted() {
            this.getRaporDetail();
		},
		methods : {
            
            async getRaporDetail() {
                this.isLoading = true;
                try {
                    let res    = await this.$store.dispatch("getRaporPerKuis", {
                        id_kuis           : this.$route.params.id_kuis
                    });
                    let result = res.data;
                    this.isLoading = false;
                    if(result.status == 'success') {
                        this.kuis            = result.data.kuis;
                        this.last_nilai      = result.data.last_nilai;
                        this.nilai_tertinggi = result.data.nilai_tertinggi;

                        let skor_list      = result.data.last_nilai;
                        let series_data     = [];
                        let categories_data = [];
                        for(var i = 0; i < skor_list.length; i++) {
                            series_data[i] = Number(skor_list[i].nilai_akhir);
                            categories_data[i] = `Percobaan ${skor_list[i].akses_ke}`;
                        }

                        this.series = [{
                            name : "Nilai Akhir",
                            data: series_data.reverse()
                        }]
                        this.chartOptions.xaxis.categories = categories_data.reverse();

                    } else {
                        showErrorNotif(this, result.message);
                    }
                } catch (error) {
                    this.isLoading = false;
                    errorHandler(this, error);
                }
            },

        }

    }
</script>
